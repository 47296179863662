<template>
    <div class="c-app flex-row align-items-center login-layout" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <div class="header">
      <CContainer>
        <div class="header-items">
          <img :src="require('@/assets/images/logo.png')" alt="" class="logo">
          <a @click="redirectToLogin" class="sign-up">Sign In</a>
        </div>
      </CContainer>
    </div>
    <CContainer>
      <CToaster :autohide="3000">
        <template v-for="info in infoList">
          <CToast :key="info.message" :show="true" :header="info.header" :color="info.color">
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>

      <div class="login-card-layout">
        <div class="login-text">
          <h1 class="login-text-heading">Create an Account</h1>
          <p class="login-text-paragraph">
            Create an account now and
            <br>
            explore our amazing features
          </p>
        </div>
        <div class="login-card-body-layout">
          <CCardBody class="login-card-body">
            <CForm class="mb-2 text-center">
              <h1 class="login-card-header">Sign Up</h1>
              <div class="form-group-login">
                <input type="text" class="form-control floating-label-input"
                  v-model="registrationObj.userName" @focus="isUsernameInputFocused = true" @blur="isUsernameInputFocused = false" />
                <label for="username" class="floating-label"
                  :class="{ 'floating': isUsernameInputFocused || registrationObj.userName }">
                User Name
                </label>
              </div>
              <div class="form-group-login">
                <input type="text" class="form-control floating-label-input"
                  v-model="registrationObj.name" @focus="isNameInputFocused = true" @blur="isNameInputFocused = false" />
                <label for="username" class="floating-label"
                  :class="{ 'floating': isNameInputFocused || registrationObj.name }">
                Name
                </label>
              </div>
              <div class="form-group-login">
                <input type="text" class="form-control floating-label-input"
                  v-model="registrationObj.email" @focus="isEmailInputFocused = true" @blur="isEmailInputFocused = false" />
                <label for="username" class="floating-label"
                  :class="{ 'floating': isEmailInputFocused || registrationObj.email }">
                  Email Address
                </label>
              </div>
              <div class="form-group-login">
                <input type="password" class="form-control floating-label-input"
                v-model="registrationObj.password" @focus="isPasswordInputFocused = true" @blur="isPasswordInputFocused = false" />
                <label for="password" class="floating-label"
                  :class="{ 'floating': isPasswordInputFocused || registrationObj.password }">
                  Password
                </label>
              </div>
              <div class="input-with-prefix">
                <span class="prefix">+60</span>
                <input type="tel" class="form-control floating-label-input"
                v-model="registrationObj.phoneNumber" @focus="isPhoneNumberInputFocused = true" @blur="isPhoneNumberInputFocused = false" />
                <label for="password" class="floating-label"
                  :class="{ 'floating': isPhoneNumberInputFocused || registrationObj.phoneNumber }">
                </label>
              </div>
              <CRow>
                <CCol col="12" class="text-center">
                  <CButton color="primary" class="px-4 login-button" @click.prevent="register">Sign Up</CButton>
                </CCol>
              </CRow>
            </CForm>
          </CCardBody>
          <p class="login-info">Never share any of your login details with anyone.</p>
        </div>
      </div>
    </CContainer>
  </div>
</template>

<script>
// let apiUrl = process.env.VUE_APP_API_URL;
let apiUrl = process.env.VUE_APP_API_URL;

export default {
  name: 'Register',
  data: () => {
    return {
      infoList: [],
      registrationObj: {
          userName: "",
          name: "",
          email: "",
          password: "",
          phoneNumber: ""
      },
      inputValue: '',
      isEmailInputFocused: false,
      isPasswordInputFocused: false,
      isUsernameInputFocused: false,
      isNameInputFocused: false,
      isPhoneNumberInputFocused: false,
      userPhoneNumber: '',
      userId: '',
      temp: null,
    };
  },
  computed: {
    inputId() {
      return 'floating-label-input-' + this._uid
    }
  },
  methods: {
    register() {
      console.log('Logging In!')
      // this.$router.push({ path: "verify-account" });
      // this.twilioVerifyOTP()
      var url = `${apiUrl}auth/register`;
      // var url = `http://localhost:3000/v1/auth/register`;
      let payload = {
        userName: this.registrationObj.userName,
        name: this.registrationObj.name,
        email: this.registrationObj.email,
        password: this.registrationObj.password,
        phoneNumber: this.registrationObj.phoneNumber
      }
      window.localStorage.setItem('query', JSON.stringify(payload));
      return api.call('post', url, payload).then((res) => {
        if (res.status === 201) {
          this.redirectToVerifyAccount()
          this.twilioVerifyOTP()
        }
      }).catch(({data}) => {
        this.toast(
          "Error",
          data.message,
          "danger"
        );
      })
    },
    twilioVerifyOTP() {
      const url = `${apiUrl}/twiliosms/`;
      let payload = {
        phoneNumber: this.registrationObj.phoneNumber
      }
      window.localStorage.setItem('query', JSON.stringify(payload));
      return api.call('post', url, payload).then((res) => {
        if (res.status === 201) {
          console.log(res)
          this.redirectToVerifyAccount()
        }
      }).catch(({data}) => {
        this.toast(
          "Error",
          data.message,
          "danger"
        );
      })
    },
    redirectToLogin() {
      this.$router.push({ path: "login" });
    },
    redirectToVerifyAccount() {
      this.$router.push({ path: "verify-account" });
    },
    toast(header, message, color) {
      this.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
  }
}
</script>

<style>
.logo {
  width: 100%;
  background: #fff;
  max-width: 115px;
}
.form-group-login {
  position: relative;
  margin-bottom: 24px;
}

.floating-label-input {
  border: none;
  border-bottom: 1px solid #D9D9D9;
  padding: 0.5rem;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #262626;
  border-radius: 0;
}

.floating-label-input:focus {
  outline: none;
  border-color: #0A1439;
  box-shadow: none;
  border-radius: 0;
}

.floating-label {
  position: absolute;
  top: 0;
  left: 8px;
  pointer-events: none;
  font-size: 16px;
  transition: all 0.2s ease;
}

.floating {
  transform: translateY(-100%);
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #0A1439;
}

.login-layout {
  background: #0A1439;
  position: relative;
}

.login-layout:after {
  background: #fff;
  content: '';
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 33%;
}

.login-text {
  flex-grow: 1;
}

.login-card-header {
  font-size: 24px;
  font-weight: 700;
  line-height: 29px;
  margin-bottom: 40px;
}

.login-card-layout {
  display: flex;
  align-items: center;
}

.login-card-body-layout {
  max-width: 475px;
  z-index: 2;
  width: 100%;
}

.login-card-body {
  background: #fff;
  width: 100%;
  flex: 0 0 auto;
  border-radius: 8px;
  padding: 80px 40px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.login-text-heading {
  font-size: 48px;
  font-weight: 700;
  line-height: 55px;
  color: #fff;

}

.login-text-paragraph {
  font-size: 18px;
  line-height: 25px;
  color: #fff;
  padding-top: 16px;
}

.login-card-util {
  padding-top: 24px;
}

.forgot-password {
  font-size: 16px;
  line-height: 19px;
  color: #8C8C8C;
  text-decoration: underline;
  transition: 0.3s ease;
}

.remember-me {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: #8C8C8C;
}

.login-button {
  width: 100%;
  display: block;
  margin-top: 40px;
  padding: 18px;
  max-width: 300px;
  background: #2566EB;
  border-radius: 35px;
  font-size: 16px;
  font-weight: 700;
  margin-inline: auto;
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 32px;
  color: #fff;
}

.header-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input-with-prefix {
  display: flex;
  align-items: center;
}

.prefix {
  margin-right: 8px; /* Adjust the spacing as needed */
  font-size: 16px;
}
</style>
